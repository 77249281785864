<template>
  <VCol
    v-if="items.length"
    class="d-flex flex-wrap"
    cols="12"
  >
    <span class="before-after px-3 category">{{ getCategory }}</span>
    <span class="before-after px-3 category">{{ getCategory }}</span>
    <VCol cols="6">
      <VExpansionPanels>
        <VExpansionPanel
          v-for="(item, index) in items"
          :key="item.documentId"
          :ref="`current-values-${index}`"
          style="max-width: 100%"
          @click="openNotSelectedPanel(index, 'current')"
        >
          <VExpansionPanelHeader>
            <span class="font-12">
              {{ item[type].foodTypeTitle }}:
              <span class="input-title ml-0">
                {{ item[type].currentValues.title }}
              </span>
            </span>
          </VExpansionPanelHeader>
          <VExpansionPanelContent>
            <VCol cols="12">
              <ModerationItem
                :changed-fields="item.changedValues"
                disabled
                is-original
                :item="item[type].currentValues"
                :type="type"
              />
            </VCol>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCol>
    <VCol cols="6">
      <VExpansionPanels>
        <VExpansionPanel
          v-for="(item, index) in items"
          :key="item.documentId"
          :ref="`moderation-values-${index}`"
          style="max-width: 100%"
          @click="openNotSelectedPanel(index, 'moderation')"
        >
          <VExpansionPanelHeader>
            <span class="font-12">
              {{ item[type].foodTypeTitle }}:
              <span class="input-title ml-0">
                {{
                  item[type].currentValues.title === item[type].moderationValues.title
                    ? item[type].currentValues.title
                    : item[type].moderationValues.title }}
              </span>
            </span>
          </VExpansionPanelHeader>
          <VExpansionPanelContent>
            <VCol cols="12">
              <ModerationItem
                :ref="type"
                :changed-fields="item[type].changedValues"
                :item="item[type].moderationValues"
                :item-current="item[type].currentValues"
                :type="type"
                @accepted="acceptModeration($event, item, type, index)"
                @declined="declineModeration($event, item, type, index)"
              />
            </VCol>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCol>
  </VCol>
</template>

<script>
import ModerationItem from '@/components/ModerationItem.vue';
import { postModerationDocument } from '@/api/api';

const componentTitles = {
  food: 'Блюда',
  foodType: 'Категория',
};
const typeOfItems = {
  food: 'food',
  foodType: 'category',
};

export default {
  components: {
    ModerationItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
  },
  emits: ['moderated'],
  computed: {
    getCategory() {
      return componentTitles[this.type];
    },
  },
  methods: {
    acceptModeration(fieldsToAccept, item, type, index) {
      const data = {
        state: 'accepted',
        entity_type: typeOfItems[type],
        [typeOfItems[type]]: {
          ...this.removeNullFormObject(fieldsToAccept),
        },
      };
      this.moderate(item.documentId, type, index, data);
    },
    declineModeration(comment, item, type, index) {
      const data = {
        state: 'rejected',
        comment,
        entity_type: typeOfItems[type],
      };
      this.moderate(item.documentId, type, index, data);
    },
    moderate(documentId, type, index, data) {
      postModerationDocument(documentId, data).then(() => {
        this.$emit('moderated', {
          documentId,
          type,
        });
      }).catch(() => {
        this.$refs[type][index].showButtons();
      });
    },
    openNotSelectedPanel(index, selected) {
      const notSelectedRow = ['current', 'moderation'].filter((row) => row !== selected);
      this.$refs[`${notSelectedRow[0]}-values-${index}`][0].toggle();
    },
    removeNullFormObject(obj) {
      // eslint-disable-next-line no-unused-vars
      return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-header > span {
  transition: transform .3s;
}
.v-expansion-panel-header--active > span {
  display: inline-block;
  transform: scale(1.1) translateX(30px);
}
.before-after {
  width: 50%;
}
.category {
  font-size: 20px;
}
</style>
