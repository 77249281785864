<template>
  <div>
    <div class="item">
      <div v-if="changedFields.includes('title')" class="dot" />
      <NamedRuledTextField
        class="py-0"
        :disabled="disabled || !changedFields.includes('title')"
        :input-width="fieldWidth"
        placeholder="Добавьте название"
        :text="itemToEdit.title"
        title="Название"
        :title-width="titleWidth"
        @update="itemToEdit.title = $event"
      />
    </div>
    <div v-if="type !== 'foodType'" class="item">
      <div v-if="changedFields.includes('price')" class="dot" />
      <NamedRuledTextField
        class="py-0"
        :disabled="disabled || !changedFields.includes('price')"
        :input-width="fieldWidth"
        placeholder="Добавьте цену"
        :text="itemToEdit.price"
        title="Цена"
        :title-width="titleWidth"
        @update="itemToEdit.price = $event"
      />
    </div>
    <VCol
      v-if="type !== 'foodType'"
      class="py-0 d-flex align-center item"
      cols="12"
    >
      <div v-if="changedFields.includes('logo')" class="dot" />
      <VCol :cols="titleWidth">
        <span class="input-title">Изображение</span>
      </VCol>
      <VCol :cols="fieldWidth">
        <ImagePreview
          :chosen-image="item.logo || itemToEdit.logo"
          class="py-0"
          :cols="12"
        />
      </VCol>
    </VCol>
    <div v-if="type !== 'foodType'" class="item">
      <div v-if="changedFields.includes('selling_text')" class="dot" />
      <DescriptionText
        class="py-0"
        :description="itemToEdit.sellingText"
        :disabled="disabled || !changedFields.includes('selling_text')"
        :show-input-description="false"
        @update="itemToEdit.sellingText = $event.sellingText"
      />
    </div>
    <ModerationAcception
      v-if="!isModerated && !isOriginal"
      @accepted="acceptItem"
      @declined="declineItem"
    />
  </div>
</template>

<script>
import NamedRuledTextField from '@/components/NamedRuledTextField.vue';
import ImagePreview from '@/components/ImagePreview.vue';
import DescriptionText from '@/components/DescriptionText.vue';
import ModerationAcception from '@/components/ModerationAcception.vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    itemCurrent: {
      type: Object,
      default: () => ({}),
    },
    changedFields: {
      type: Array,
      default: () => [],
    },
    isOriginal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImagePreview,
    NamedRuledTextField,
    DescriptionText,
    ModerationAcception,
  },
  emits: ['accepted', 'declined'],
  data() {
    return {
      titleWidth: 3,
      fieldWidth: 8,
      isModerated: false,
      itemToEdit: {
        title: this.item.title || this.itemCurrent.title,
        price: this.item.price || this.itemCurrent.price,
        sellingText: this.item.sellingText || this.itemCurrent.sellingText,
        logo: this.item.logo || this.itemCurrent.logo,
      },
    };
  },
  methods: {
    acceptItem() {
      const changesLength = Object.keys(this.item).filter(
        (key) => this.itemToEdit[key] !== this.item[key],
      );
      if (changesLength.length > 0) {
        this.$amplitudeEvent('moderation_accept_with_changes');
      } else {
        this.$amplitudeEvent('moderation_accept');
      }
      const item = {
        title: this.itemToEdit.title,
        price: this.itemToEdit.price,
        selling_text: this.itemToEdit.sellingText,
        logo: this.itemToEdit.logo,
      };
      this.$emit('accepted', item);
      this.isModerated = true;
    },
    declineItem(comment) {
      this.$amplitudeEvent('moderation_reject');
      this.isModerated = true;
      this.$emit('declined', comment);
    },
    showButtons() {
      this.isModerated = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixin.scss";

.item {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  .dot {
    position: absolute;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $hover_red_color;
    animation: echo 3s infinite;
  }
}

@keyframes echo {
  from, to {
    box-shadow: 0 0 0 0 white, 0 0 3px 0 $hover_red_color;
  }
  50% {
    box-shadow: 0 0 0 7px white, 0 0 3px 6px $transparent_red_color;
  }
}
</style>
