<template>
  <VCol class="d-flex flex-wrap justify-center" cols="12">
    <VBtn
      class="default-btn mr-3 px-5"
      color="#333"
      dark
      min-width="150"
      rounded
      @click="isDeclined = true"
    >
      Отклонить
    </VBtn>
    <VBtn
      class="default-btn ml-3 px-5"
      color="#4caf50"
      :disabled="isDeclined"
      min-width="150"
      rounded
      @click="accept"
    >
      Принять
    </VBtn>
    <Transition name="appearance">
      <div v-if="isDeclined" class="comment d-flex flex-wrap justify-center mt-4 width-100-percent">
        <VCol class="pr-0 pl-3 d-flex flex-wrap align-center" cols="3">
          <span class="input-title">Причина отказа</span>
        </VCol>
        <VCol cols="9">
          <VTextarea
            ref="comment"
            v-model="comment"
            auto-grow
            autofocus
            label="Опишите причину отказа"
            :rules="commentValidation"
            solo
          />
        </VCol>
        <VCol class="d-flex justify-center" cols="12">
          <VBtn
            class="default-btn mr-3"
            color="#333"
            dark
            min-width="200"
            rounded
            @click="isDeclined = false"
          >
            Вернуться к проверке
          </VBtn>
          <VBtn
            class="default-btn ml-3"
            color="#4caf50"
            dark
            min-width="150"
            rounded
            @click="decline"
          >
            Отправить
          </VBtn>
        </VCol>
      </div>
    </Transition>
  </VCol>
</template>

<script>
export default {
  emits: ['accepted', 'declined'],
  data() {
    return {
      isDeclined: false,
      comment: '',
      commentValidation: [
        (text) => text.length > 0 || 'Заполните поле комментария к правкам категории',
      ],
    };
  },
  methods: {
    accept() {
      this.$emit('accepted');
    },
    decline() {
      if (this.$refs.comment.validate()) {
        this.$emit('declined', this.comment);
        this.isDeclined = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-btn__content {
    color: white !important;
  }
}

.appearance-enter,
.appearance-leave-to {
  transform: rotateX(90deg) scale(0);
  position: absolute;
}

.appearance-enter-active,
.appearance-leave-active {
  transition: transform .4s;
}

</style>
